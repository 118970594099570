import Layout from "@/layout"

const syncRouter=[
    {
      path: "/personHome",
      component: Layout,
      children:[
        {
          path: "",
          name: "PersonHome",
          component: () => import("@/views/personHome/index.vue"),
          meta: {
            title:"个人中心",
          }
      }
      ]
    },
  ]
  export default syncRouter