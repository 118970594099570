import request from "@/utils/requestor/request"

// 资讯-热文排行 top 5(右侧 政策推荐)
const getNewsArticleTop5=()=>request({url:"/article/NewsArticleTop"})

// 获取文章分类
const getArticleType=(lx=0)=>request({url:"/type/drowdown",params:{lx}})

// 获取文章列表
const getArticleList=(params)=>request({url:"/article/page",params:Object.assign(params,{state:1})})

// 获取政策文件top5
const getPoliciesTop5=(params)=>request({url:"/article-library/front/list",params})

// 招投标信息top5
const getBidTop5=(params)=>request({ url:"the-bidding/front/list",params})

// 获取文章分类(资讯 政策 招投标)
const getAllInfoTree=()=>request({url:"/type/tree"})

// 获取热门作者Top5
const getHotAuthor=()=>request({url:"/article/NewsAuthorTop"})

// 获取热门文章Top5
const getHotArticle=()=>request({url:"/article/NewsArticleTop"})

// 获取文章详情
const getArticleDetail=(params)=>request({url:`/article/Front/${params.id}`})

// 获取作者信息
const getAuthorInfo=(params)=>request({url:"/article/Front/GetAuthor", params})

// 获取作者所有文章
const getArticleByAuthorId=(params)=>request({url:"/article/author/front/page", params})


export default{
    getNewsArticleTop5,
    getArticleType,
    getArticleList,
    getPoliciesTop5,
    getBidTop5,
    getAllInfoTree,
    getHotAuthor,
    getHotArticle,
    getArticleDetail,
    getAuthorInfo,
    getArticleByAuthorId,
}