import Cookies from "js-cookie"

class CookieControl {
    setCookie(key,val,expires=7){
        Cookies.set(key,val,{expires})
    }
    getCookie(key=""){
        return Cookies.get(key)
    }
    removeCookie(key=""){
        Cookies.remove(key)
    }
}
export default new CookieControl()