import request from "@/utils/requestor/request"

// 政策文库分页
const getLibraries=(params)=>request({url:"/article-library/front/page",params})

// 政策文库详情
const getLibraryDetail=(id)=>request({url:`/article-library/front/${id}`})

//判断是否可以跳转链接
const whetherJumpDetail=(id)=>request({url:`/article-library/front/jump/${id}`})

export default{
    getLibraries,
    getLibraryDetail,
    whetherJumpDetail
}