<template>
  <div class="regi_load rel login-regist-form regist" v-show="showRegistForm">
    <span class=" iconfont abs close" @click="closeForm()">
          <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-close"></use>
                    </svg>
      </span>
    <img :src="require('@/assets/images/logo.png')" class="db logo" />
    <el-form
      ref="registForm"
      class="form"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="" prop="phoneNo">
          
        <el-input v-model="form.phoneNo" placeholder="请输入手机号" class="phone-inp"  maxlength="11" v-disableSpace>
          <template #prefix>
            <span class="country-code">+86</span>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="" prop="phoneCode">
        <el-input v-model="form.phoneCode" placeholder="请输入短信验证码" v-disableSpace>
          <template #suffix>
            <span class="get-code" :class="[form.phoneNo.length===11?'pointer':'disable']" @click="getPhoneCode()">{{phoneCodeTips}}</span>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input v-model="form.password" placeholder="请设置新密码/8~20位数字/字母" v-disableSpace type="password"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="form.repeatPwd" placeholder="请再次输入新密码" v-disableSpace type="password"></el-input>
      </el-form-item>
      <!-- 注册协议 -->
      <el-checkbox v-model="protocal" >
        <span style="color: black;">我已阅读并同意</span><a href="javascript:void(0);" @click="showProtocal()">《医信邦用户注册协议》</a>
      </el-checkbox>
      <!-- end注册协议 -->

    </el-form>
     <el-button type="primary" class="reg-btn" :disabled="disable" @click="register()">{{formType}}</el-button>
  </div>
</template>

<script setup>
import { computed, defineComponent,ref,watch, toRefs } from "vue";
import {useRouter} from 'vue-router'
import { ElForm, ElInput, ElFormItem,ElButton } from "element-plus";
import useRegistForm from "@/composable/login_regist/useRegistForm"
import { useStore } from "vuex";

const store=useStore()
    const showRegistForm=computed(()=>store.state.popup.showRegistForm)
    const formType=ref("注 册")
    watch(showRegistForm,(val)=>{
      if(!!val){
        const isFindPwd=sessionStorage.getItem("findPwd")
        if(isFindPwd){
          formType.value="找回密码"
          sessionStorage.removeItem("findPwd")
        }else{
          formType.value="注册"
        }
      }
    })
    const closeForm=()=>store.commit("toggleRegistForm",false)
    const {protocal,form,ruleState,registForm,getPhoneCode,phoneCodeTips,disable,register}=useRegistForm(store,formType)
    const {rules}=toRefs(ruleState)

    const router = useRouter()
    const showProtocal = () => {
     const {href} = router.resolve({
        name: 'Protocal'
      })
      window.open(href,'_blank')
    }
</script>
<style lang="less" scoped>
:deep(.el-input__inner){
  border-radius: 4ch !important;
}
:deep(.phone-inp>input){
  padding-left: 50px !important;

}
.form{
  width: 296px;
  margin: 0 auto;
}
.logo{
  margin: 40px auto 40px !important;
}
.country-code{
  color: black;
}

.pointer{
    color: #1188f7;
    cursor: pointer;
}
.disable{
  cursor: not-allowed;
}
.reg-btn{
  position: absolute;
    top: 400px;
    line-height: 17px;
    height: 34px;
}
.close{
  top: 4px;
  right: 4px;
}

</style>
