<template>
    <!-- 升级为高级或超级会员 -->
<div id="hp">
    <div class="success_box rel become-member container" v-show="showBecomeMemberForm">
      <span class=" iconfont abs close" @click="closeForm()">
          <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-close-white"></use>
                    </svg>
      </span>
        
        <div class="advanced" v-for="item in memberLevel" :key="item.id">
          <el-radio v-model="memberType" :label="item.name" @change="toggleType(item)" ><span class="fs-white">{{item.name}}</span><span class="f36 fs-white">{{item.cost}}</span ><span class="fs-white">元</span></el-radio>
          <p class="tips">免费查看{{item.library}}条政策信息，{{item.bidding}}条招投标信息 超出信息另付费</p>
        </div>
        <el-skeleton style="width: 100%;margin:28px 0 10px;display: flex;" :loading="loading" v-loading="loading"
    element-loading-text="二维码加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0)" animated>
      <template #template>
        <el-skeleton-item
          variant="rect"
          style="width: 130px; height: 130px;margin: 0 auto;"
        />
      </template>
      <template #default>
        <div class="code-container"><img class="qrcode" :src="code"/></div>
      </template>
    </el-skeleton>
        <p class="tc remark">请用微信扫码支付</p>
    </div>
</div>
</template>
<script setup>
import { ref,computed, watch,reactive ,toRefs} from "vue"
import {useStore} from "vuex"
import $api from "@/api"

const store=useStore()

        const showBecomeMemberForm=computed(()=>store.state.popup.becomeMemberForm)
        const closeForm=()=>store.commit("toggleBecomeMemberForm",false)

        const memberType=ref("")
        const memberLevel=ref([])
        const getMemberClassies=async()=>{
            const [err,data]=await $api.user.getMemberClassies()
            memberLevel.value=data.data.items.sort((a,b)=>a.cost-b.cost)
            const sessionMemberType=sessionStorage.getItem('memberType')
            sessionStorage.removeItem('memberType')
            memberType.value=sessionMemberType ?? memberLevel.value[0].name;
        }
        const toggleType=(item)=>{
            getPayQrCode(item.id)
        }

        //#region  获取二维码
        const codeInfo=reactive({
            money:0,
            code:""
        })
        const loading=ref(true)
        const getPayQrCode=async(id)=>{
            loading.value=true
          const [err,data]=await $api.payment.getPayQrCode({ProductId:id,lx:3,connectionId:globalThis.connectionId})
          if(!data.succeeded)return;
          codeInfo.money=data.data.money
          codeInfo.code=`data:image/svg+xml;base64,${data.data.images}`
          loading.value=false
        }
        //#endregion
        
        watch(store.state.popup,(val)=>{
            if(val.becomeMemberForm){
                getMemberClassies().then(()=>{
                  const [{id:productId}] = memberLevel.value.filter(e=>e.name === memberType.value)
                  getPayQrCode(productId)
            })
            }
        })
        const {money,code}=toRefs(codeInfo)
</script>
<style lang="less" scoped>
    .advanced{
        padding-top: 55px;
        color:rgba(255,255,255,0.6);
        margin: 20px 20px;
    }
    .tips{
    margin-left:18px;
    margin-top: 5px;
    line-height: 24px;
    }
    .container{
      height: auto;
    padding-bottom: 8px;
}
.qrcode{
  text-align: center;
  transform: scale(.6);
  margin-top:-20px;
}
.close{
    right: 4px;
    top: 4px;
}
.code-container{
    text-align: center;
}

// 2021 10 22
.advanced{padding-top:12px;padding-bottom:15px;}
.success_box p{font-size:12px;}
// .tips{margin-left:18px;margin-top:5px;}
/deep/.el-radio__label{margin-left:0;padding-left:0;}
.icon{opacity: 0.8;}
.remark{margin-top: -33px;}
.code-container{height: 164px;}
.container{padding-bottom: 30px;}

</style>