import request from "@/utils/requestor/request"

//获取注册验证码
const getPhoneCode=(params)=>request({url:"/sms/sms",params})

// 注册
const register=(data)=>request({ url:"/member/register",method:"POST",data})

// 账密登录
const loginByPwd=(data)=>request({url:"/member/pwdlogin",method:"POST",data})

// 账号验证码登录
const loginByCode=(data)=>request({ url:"/member/codelogin", method:"POST",data})

// 找回密码
const findPwd=(data)=>request({url:"/member/reset/password",data,method:"POST"})

// 获取用户信息
const getUserInfo=()=>request({url:"/member/PageInfo"})

// 获取会员分类
const getMemberClassies=()=>request({ url:"/vip/page"})

// 获取用户订单
const getOrder=(params)=>request({url:"/order/Front/page",params})

// 获取用户已发表文章
const getPersonalArticles=(params)=>request({url:"/article/FrontPage",params})

// 删除文章
const removeArticle=(data)=>request({url:"/article/deleteFront",data,method:"POST"})

// 发布文章
const releaseArticle=(data)=>request({url:"/article/set",data,method:"POST"})

// 修改密码
const changePassword=(data)=>request({url:"/user/SetPassWordePage",data,method:"POST"})

// 修个个人基本信息
const editBaseInfo=(data)=>request({url:"/member/edit",method:"POST",data})

// 获取用户会员等级
const getMemberLevel=(id)=>request({url:`/member/${id}`})

// 获取所有会员等级
const getAllMembers=()=>request({url:"/vip/FrontPage"})

// 获取首页轮播图
const getBannerPics=()=>request({url:"/rotation/list"})
export default{
    getPhoneCode,
    register,
    loginByCode,
    loginByPwd,
    findPwd,
    getUserInfo,
    getMemberClassies,
    getOrder,
    getPersonalArticles,
    removeArticle,
    releaseArticle,
    changePassword,
    getMemberLevel,
    getAllMembers,
    getBannerPics,
    editBaseInfo
}