
export const validLength=(rule, value, callback)=>{
    if(!value.length){
        return callback(new Error(`请填写${rule?.name??'内容'}`))
    }else{
        callback()
    }
}
export const validPhone=(rule, value, callback)=>{
    const phoneReg=/^(?:(?:\+|00)86)?1[3-9]\d{9}$/
    const landLine=/^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/
    if(phoneReg.test(value) || landLine.test(value)){
       callback()
    }else{
        return callback(new Error(`联系方式有误,请重新输入`))
    }
}
export const validEmail=(rule, value, callback)=>{
    const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(!emailReg.test(value)){
        return callback(new Error(`电子邮箱有误,请重新输入`))
    }else{
        callback()
    }
}
export const validUrl=(rule, value, callback)=>{
    const urlReg=/^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/
    if(!urlReg.test(value)){
        return callback(new Error(`链接地址有误,请重新输入`))
    }else{
        callback()
    }
}
export const validPassword=(rule,value,cb)=> { 
    if(value.length>7 && value.length<21){
        cb()
    }else{
        return cb(new Error("密码长度有误,请重新输入"))
    }
 }
 export const validPhoneCode=(rule,value,cb)=>{
     if(value.length){
         cb()
     }else{
         return cb(new Error("请填写手机验证码"))
     }
 }