import router from "@/router"
import syncRouter from "@/router/syncRouter"
import $api from "@/api"
import $cookie from "@/utils/cookie"
if($cookie.getCookie("token")){
    syncRouter.forEach(e=>router.addRoute(e))
}
const userState={
    state:{
        userInfo:null,
        token:"",
        level:0  //0 普通会员 1 高级会员 2 超级会员
    },
    mutations:{
        setUserInfo(state,payload){
            state.userInfo=payload;
            localStorage.setItem("userInfo",JSON.stringify(payload))
        },
        setToken(state,payload){
            state.token=payload;
            $cookie.setCookie("token",payload)
        },
        setLevel(state,payload){
            state.level=payload
        },
        loginOut(state){
            state.userInfo=null;
            state.token=""
            localStorage.clear()
            sessionStorage.clear()
            globalThis?.stopSignalr()
            $cookie.removeCookie("token")
        }
    },
    actions:{
        getUserInfo({commit}){
            return new Promise((resolve,reject)=>{
                $api.user.getUserInfo().then(([err,data])=>{
                    if(!data.succeeded){
                        reject("userState:not login")
                    }else{
                        // 避免重复添加
                        if(router.getRoutes().every(e=>e.name!=="PersonHome")){
                            syncRouter.forEach(e=>router.addRoute(e))
                        }
                        commit("setToken",$cookie.getCookie("token"))
                        commit("setLevel",data.data.level)
                        resolve(data.data)
                    }
                }).catch(err=>{
                    reject(err)
                })
            })
        }
    }
}
export default userState