import request from "@/utils/requestor/request"

// 获取所有厂商列表
const getCompanyList=(params)=>request({url:"/vendors/Front/page",params})

// 获取厂商top5
const getCompanyTop=(params)=>request({url:"/vendors/Frontpage",params})

// 获取厂商详情
const getCompanyInfo=(params)=>request({url:`/vendors/VendorsDetailFront/Id`,params})

// 获取厂商文章
const getCompanyArticle=(params)=>request({ url:"/article/vendors/front/page", params})

// 认领厂商
const claimCompany=(data)=>request({url:"/vendors/FrontSet",method:"POST",data})

// 获取个人中心厂商信息
const getCompanyDetail=(id)=>request({url:`/vendors/${id}`})

// 修改厂商信息
const modifyCompanyInfo=(data)=>request({url:"/vendors/Front/set",method:"POST",data})

// 验证厂商名称
const validCompanyName=(params)=>request({url:"/vendors/Front/getVendorsName",params})

// 厂商入住
const companyIntake=(data)=>request({url:"/vendors/Front/set",method:"POST",data})
export default{
    getCompanyList,
    getCompanyTop,
    getCompanyInfo,
    getCompanyArticle,
    claimCompany,
    getCompanyDetail,
    modifyCompanyInfo,
    validCompanyName,
    companyIntake
}