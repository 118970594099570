<template>
    <div id="hp">
        
    <div class="success_box rel update-member" v-show="showUpdateMemberForm">
      <span class=" iconfont abs close" @click="closeForm()">
          <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-close-white"></use>
                    </svg>
      </span>
      
        <div class="radio_box">
          <div class="radio_title f20">升级超级会员</div>
          <el-radio v-model="memberType" label="超级会员">超级会员<span class="f36">{{money}}</span>元</el-radio>
          <p class="tips">免费查看200条政策信息，200条招投标信息，超出信息另付费</p>
        </div>
        <el-skeleton style="width: 100%;margin:28px 0 10px;display: flex;" :loading="loading" v-loading="loading"
    element-loading-text="二维码加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0)" animated>
      <template #template>
        <el-skeleton-item
          variant="rect"
          style="width: 130px; height: 130px;margin: 0 auto;"
        />
      </template>
      <template #default>
        <div class="qrcode" >
          <img :src="code" alt="" srcset="">
        </div>
      </template>
    </el-skeleton>

        <p class="tc remark">请用微信扫码支付</p>
    </div>
</div>
</template>
<script setup>
import { computed, ref,watch,reactive,toRefs } from 'vue'
import {useStore} from "vuex"
import $api from "@/api"
const store=useStore()
        const loading=ref(true)
        const showUpdateMemberForm=computed(()=>store.state.popup.updateMemberForm)
        const closeForm=()=>store.commit("toggleUpdateMemberForm",false)

        const memberType=ref("超级会员")
        const codeInfo=reactive({
          money:0,
          code:""
        })
        const getPayQrCode=async()=>{
            loading.value=true
          const [err,data]=await $api.payment.getPayQrCode({ProductId:0,lx:4,connectionId:globalThis.connectionId})
          loading.value=false
          if(!data.succeeded) return;
                codeInfo.code=`data:image/svg+xml;base64,${data.data.images}`
                codeInfo.money=data.data.money
        }
        watch(store.state.popup,(val)=>{
          if(val.updateMemberForm){
            getPayQrCode()
          }
        })
        const {code,money}=toRefs(codeInfo)
</script>
<style lang="less" scoped>
:deep(.el-radio__label){
    padding-left: 0;
    color: white !important;
    margin: 10px 0;
    display: inline-block;
}
.update-member{
    height: auto;
}
.radio_box{
    color: #fff;
    padding-top: 107px;
    margin: 0 20px 125px;
}
.radio_title{
    padding-left: 32px;
}
.tips{
    padding-left: 34px;
    opacity: 0.6;
}
.qrcode{
    transform: scale(.6);
    text-align: center;
}
.close{
        right: 4px;
    top: 4px;
}


// 2021 10 22
.remark{margin-top: -35px;margin-bottom:20px;}
.icon{opacity: 0.6;}
</style>
