<template>
<!-- 普通付费信息弹窗 -->
    <div id="hp">
    <div class="success_box rel box-shadow" v-show="showOrdinaryForm">
      <span class=" iconfont abs close" @click="closeForm()">
          <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-close-white"></use>
                    </svg>
      </span>
      <img :src="require('@/assets/images/yxb-white.png')" class="succ_logo db">
      <div class="info_box auto pt30 container">

        <p >该条信息为付费信息，支付{{money}}元后，即可查看 或<span v-if="orderInfo.tips"><a href="javascript:void(0)" @click="becomeMember(orderInfo.tips)" class="dib tc mr5 ml5">{{orderInfo.tips}}</a>查看更多</span></p>
        <p class="cf">
          <label class="fl bold">订单内容</label>
          <span class="fl box_title">{{orderInfo.title}}</span>
        </p>
        <p class="cf">
          <label class="fl bold">订单金额</label>
          <span class="fl f24 lh14 bold">{{money}}</span>
          <span class="fl">元</span>
        </p>
        <p class="cf">
          <label class="fl bold">支付方式</label>
          <span class="fl">微信支付</span>
        </p>

        

        <el-skeleton style="width: 100%;margin:28px 0 10px;display: flex;" :loading="loading" v-loading="loading"
    element-loading-text="二维码加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0)" animated>
      <template #template>
        <el-skeleton-item
          variant="rect"
          style="width: 130px; height: 130px;margin: 0 auto;"
        />
      </template>
      <template #default>
        <div class="qrcode" v-html="code" v-if="code.includes('<svg')"></div>
        <div class="qrcode" v-else>
           <img :src="code" alt="" srcset="" >
        </div>
      </template>
    </el-skeleton>
        <p class="tc mb20">请用微信扫码支付</p>
      </div>
    </div>
</div>
</template>
<script setup>
import { computed, defineComponent,ref,reactive,toRefs, watch } from 'vue'
import {useStore} from "vuex"
import $api from "@/api"

        const loading=ref(true)
        const store=useStore()
        const codeInfo=reactive({
          money:0,
          code:""
        })
        const orderInfo=computed(()=>store.state.popup.orderInfo)
        const showOrdinaryForm=computed(()=>store.state.popup.showOrdinaryForm)
        const closeForm=()=>{
          codeInfo.code=""
          codeInfo.money=0
          store.commit("toggleOrdinaryForm",false)
        }
        
        const getPayQrCode=async()=>{
          loading.value=true
          const {id,type,outerQrcode="",price}=orderInfo.value
          if(outerQrcode){
            codeInfo.code=`data:image/svg+xml;base64,${outerQrcode}`
            codeInfo.money=price
            loading.value=false
          }else{
            const [err,data]=await $api.payment.getPayQrCode({ProductId:id,lx:type,connectionId:globalThis.connectionId})
            loading.value=false
            if(!data.succeeded) return
             codeInfo.code=`data:image/svg+xml;base64,${data.data.images}`
            codeInfo.money=data.data.money
          }
        }
       
        watch(store.state.popup,(val)=>{
          if(val.showOrdinaryForm){
            getPayQrCode()
          }
        })

        const becomeMember=(tips)=>{
          store.commit("setOrderInfo",{title:"becomeMember",id:0,price:999,type:4})
          store.commit("toggleOrdinaryForm",false)
          if(tips==="成为会员"){
            store.commit("toggleBecomeMemberForm",true)
          }else{
            store.commit("toggleUpdateMemberForm",true)

          }
        }
        const {code,money}=toRefs(codeInfo)
</script>
<style lang="less" scoped>
  .flex{
        display: flex;
    align-items: center;
  }
  .mask{
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
  background: rgba(.1, .1, .1, .1);
}
.close{
    top: 4px;
    right: 4px;
}
.container{
      height: auto;
    padding-bottom: 8px;
}
.qrcode{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -30px auto;
  text-align: center;
  transform: scale(.6);
}

.success_box a{width:70px;height:30px;line-height:30px;background: #FF9600;color:#fff;border-radius: 5px;}
.box_title{width: 78%;text-align:justify;max-height: 40px;overflow: hidden;}
.success_box label{margin-right:5px;}
.success_box span{font-weight: 400;margin-left:0;margin-right:0;}
.lh14{line-height: 14px;}

.icon{opacity: 0.6;margin:5px 5px 0 0;}
.success_box a{width: auto;}
</style>