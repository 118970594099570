import {computed, reactive, ref,} from "vue"
import {useStore} from "vuex"
import dialog from "@/utils/ui/dialog";
import $api from "@/api"
export default function useLoginForm(store){
    const showPassword=ref(true)
    const showPhoneCode=ref(false)
    const form=reactive({
        phoneNo:"",
        phoneCode:"",
        password:""
    })
    const login=async()=>{
        if(!!showPassword.value){
            const [err,data]=await $api.user.loginByPwd({phone:form.phoneNo,password:form.password})
            if(!data.succeeded){
                dialog.message("error",data.errors)
                return;
            }
            for (const key in form) {
                form[key]=""
            }
            store.commit("toggleLoginForm",false)
            const {token}=data.data
            store.commit("setToken",token)
            store.dispatch('getUserInfo').then(res=>{
                store.commit("setUserInfo",res)
            }).catch(err=>{
            })
        }else{
            const [err,data]=await $api.user.loginByCode({phone:form.phoneNo,code:form.phoneCode})
            if(!data.succeeded){
                dialog.message("error",data.errors)
                return;
            }
            for (const key in form) {
                form[key]=""
            }
            store.commit("toggleLoginForm",false)
            const {token}=data.data
            store.commit("setToken",token)
            store.dispatch('getUserInfo').then(res=>{
                store.commit("setUserInfo",res)
            }).catch(err=>{
            })
        }
    }
    const disable=computed(()=>(!!form.phoneNo.length && (!!form.phoneCode.length || !!form.password.length)))
    const toggleLoginMethod=(method)=>{
       if(method=="password"){
        showPassword.value=true
        showPhoneCode.value=false
       }else{
        showPassword.value=false
        showPhoneCode.value=true
       }
    }
    return {
        login,
        showPassword,
        showPhoneCode,
        toggleLoginMethod,
        form,
        disable
    }
}