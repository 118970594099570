import {ElMessage,ElMessageBox} from "element-plus";
const dialog={
    message(type,message,duration,cb=()=>{}){
        ElMessage[type]({
            message:message,
            type,
            duration:duration || 2000,
            center:true,
            background:"#ffffff00",
            onClose:cb()
        })
    },
    alert(title,message,center=false,showClose=true,cb=()=>{}){
        ElMessageBox.alert(message,title,{
            confirmButtonText:"确定",
            center,
            showClose,
            customClass:"mask"
        }).then(()=>{
           cb()
        })
    }
    
}
export default dialog