<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>
<script>
import { onMounted, watchEffect, ref } from "vue";
import { useStore } from "vuex";
import {useRouter} from "vue-router"
import $connection from "@/utils/requestor/signalr";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import downLoadFile from "@/utils/file/downLoadFile"
import {getImgUrl} from "@/utils/imageRelated"
export default {
  setup() {
    console.log(process.env)
    const store = useStore();
    const router=useRouter()

    let $signalr = ref(null);
    watchEffect(() => {
      const token = store.state.userState.token;
      if (token) {
        $signalr = new $connection(token);
        console.log(`socket state:%c${$signalr?.instance?.state}`,"color:#35bf85;font-weight:bold");
        $signalr.receivMsg((userId, msg) => {
          const orderInfo=store.state.popup.orderInfo
          const {type}=store.state.popup.orderInfo
          switch (msg) {
            case "1":
            case "2":
              router.push({name:`${type===2?"Bidding":"Library"}Detail`,query:{id:orderInfo.id}})
              break;
            case "3":
            case "4":
             getUserInfo()
              break;
            default:
              downLoadFile(getImgUrl(msg),orderInfo.title)
              break;
          }
          closeForm()
        });
      }
    });
    const closeForm=()=>{
          store.commit("toggleOrdinaryForm", false);
          store.commit("toggleBecomeMemberForm", false);
          store.commit("toggleUpdateMemberForm", false);
    }
    const getUserInfo=()=>{
        store.dispatch("getUserInfo").then((res) => {
          store.commit("setUserInfo", res);
        }).catch((err) => {
          console.warn(`FBI WARING:${err}`);
        });
    }
    onMounted(() => {
      getUserInfo()
      window.addEventListener("offline", async() => {
        try {
          await $signalr.stop()
        } catch (error) {
        }
         closeForm()
      });
      window.addEventListener("online", async() => {
        try {
          await $signalr.start()
        } catch (error) {
        }
      });
    });
    
    return {
      locale: zhCn,
    };
  },
};
</script>
<style lang="less">
@import "./style/font.less";
@import "./style/style.less";
</style>
