import router from "@/router"
import $cookie from "@/utils/cookie"
const blackList=["/personHome"]

router.beforeEach((to,from,next)=>{
    
    const token=$cookie.getCookie("token")
   document.title = `医信邦 - ${to.meta?.title ?? "传播医信行业价值平台"}`
    if(!!token){
        next()
    }else{
        if(blackList.some(e=>e===to.fullPath)){
            next("/404")
        }else{
            next()
        }
    }
})