import request from "@/utils/requestor/request"
import axios from "axios"

//获取全国省份
const getProvince=(keywords,subdistrict)=>axios.get("/getProvince",{params:{keywords,subdistrict,key:"5f0c24cb6856146b70e326deb049c3cd"}})

// 获取分页列表
const getBiddingList=(params)=>request({url:"/the-bidding/front/page",params})

// 获取excel下载地址
const getExcelUrl=(params)=>request({url:"/WX/exportExcel/CodePay",params})

// 获取招投标详情
const getBiddingDetail=(id)=>request({url:`/the-bidding/front/${id}`})

// 2020 10 25 获取招投标详情导出
const getBiddingDetailExport=(id)=>request({url:`/the-bidding/front/exportExcel/${id}`})

// 判断链接是否能跳转
const whertherJumpDetail=(id)=>request({url:`the-bidding/front/jump/${id}`})
export default{
    getProvince,
    getBiddingList,
    getExcelUrl,
    getBiddingDetail,
    getBiddingDetailExport,
    whertherJumpDetail
}
