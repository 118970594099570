<template>
  <div class="regi_load rel login-regist-form " v-if="showLoginForm">
    <span class=" iconfont abs close" @click="closeForm()">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-close"></use>
      </svg>
    </span>
    <img :src="require('@/assets/images/logo.png')" class="db" />
    <ul>
      <li
        :class="{ active: showPassword }"
        @click="toggleLoginMethod('password')"
      >
        <span>密码登录</span>
      </li>
      <li
        :class="{ active: !showPassword }"
        @click="toggleLoginMethod('phoneCode')"
      >
        <span>手机验证码登录</span>
      </li>
    </ul>
    <!-- 密码登录 -->
    <div class="load_box" v-show="showPassword">
      <div class="input_box">
        <div class="code">+86</div>
        <input
          name=""
          v-model="form.phoneNo"
          placeholder="请输入手机号"
          class="inp inp_top"
        />
      </div>
      <input
        type="password"
        name=""
        placeholder="密码"
        v-model="form.password"
        class="inp inp_bottom db"
      />
      <div class="w100p cf">
        <a href="javascript:void(0);" @click="findPassword()" class="fr"
          >找回密码</a
        >
      </div>
    </div>
    <!-- 手机号登录 -->
    <div class="regi_box" v-show="showPhoneCode">
      <div class="input_box">
        <div class="code">+86</div>
        <input
          name=""
          placeholder="请输入手机号"
          v-model="form.phoneNo"
          class="inp inp_top"
        />
      </div>
      <div class="check_box w100p">
        <input
          name=""
          v-model="form.phoneCode"
          placeholder="短信验证码"
          class="inp inp_bottom db"
        />
        <div class="get" :class="[form.phoneNo.length===11?'pointer':'disable']" @click="getPhoneCode()">{{phoneCodeTips}}</div>
      </div>
    </div>
    <el-button
      type="primary"
      class="login-btn"
      :disabled="!disable"
      @click="login()"
      >登 录</el-button
    >
  </div>
</template>
<script>
export default defineComponent({
  name: "LoginForm",
});
</script>

<script setup>
import { computed, defineComponent, toRefs,ref } from "vue";
import { ElButton } from "element-plus";
import useLoginForm from "@/composable/login_regist/useLoginForm";
import { useStore, mapState } from "vuex";
import $api from "@/api"
const store = useStore();
const showLoginForm = computed(() => store.state.popup.showLoginForm);
const closeForm = () => store.commit("toggleLoginForm", false);

const {
  showPassword,
  showPhoneCode,
  toggleLoginMethod,
  login,
  disable,
  form,
} = useLoginForm(store);
const findPassword = () => {
  sessionStorage.setItem("findPwd", true);
  store.commit("toggleLoginForm", false);
  store.commit("toggleRegistForm", true);
};
   const phoneCodeTips=ref("获取验证码")
   let timer=ref(null)
 // 获取手机验证码
    const getPhoneCode=async()=>{
    const phoneReg=/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
        if(!phoneReg.test(form.phoneNo) || !!timer.value)return;
        const [err,data]=await $api.user.getPhoneCode({mobile:form.phoneNo})
        if(!data.succeeded){
            dialog.message("error",data.errors)
            return;
        }
        countDown()

    }

    // 倒计时60s
    const countDown=()=>{
        let defaultSecond=60;
        timer.value=setInterval(() => {
            if(defaultSecond===0){
                phoneCodeTips.value="获取验证码"
                clearInterval(timer.value)
                timer.value=null;
                return;
            }
            phoneCodeTips.value=`${--defaultSecond}S`
        }, 1000);
    }

</script>
<style lang="less" scoped>
.login-btn {
  position: absolute;
  top: 400px;
  line-height: 17px;
  height: 34px;
}
.login-regist-form {
  width: 420px;
  height: 524px;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -240px;
  margin-top: -267px;
  border-radius: 5px;
}
.close {
  top: 4px;
  right: 4px;
}
// 2021 1101
header .regi_load .regi_box .pointer{background:#1188f7;cursor:pointer;}
</style>
