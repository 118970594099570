import axios from "axios"
export default (url,title)=>{
    axios.get(url,{responseType:"blob"}).then(res=>{
        const blob=res.data;
        const downLoadEle=document.createElement("a")
        const href=URL.createObjectURL(blob)
        downLoadEle.href=href
        downLoadEle.download=title
        document.body.appendChild(downLoadEle)
        downLoadEle.click()
        document.body.removeChild(downLoadEle);
        window.URL.revokeObjectURL(href)
    }).catch(err=>{})
}