import * as signalr from "@microsoft/signalr"
class connection{
    constructor(token){
        this.token=token;
        this.instance=this.init()
        this.start().then(_=>{
            globalThis.connectionId=this.instance.connectionId
            globalThis.stopSignalr=this.stop.bind(this)
        })
    }
    init(){
      return new signalr.HubConnectionBuilder().withUrl("https://v1.hitzone.cn/hubs/chathub",{accessTokenFactory:()=>this.token}).withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000]).configureLogging(signalr.LogLevel.Error).build()
    }
    async start(){
        await this.instance.start()
    }
    async stop(){
        await this.instance.stop()
    }
    invoke(method){
        this.instance.invoke(method)
    }
    receivMsg(fn){
        this.instance.on("ReceiveMessage",(userId,msg)=>{
          fn(userId,msg)
        })
    }
}
export default connection
