import { createStore } from "vuex";
import { reactive, ref } from "vue";
import userState from "./modules/user"
import popup from "./modules/popup"
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    userState,
    popup
  },
  getters:{
    userInfo:()=>reactive({...userState.state.userInfo}),
    isLogin:(state)=>!!state.userState.token
  }
});
