import config from "@/config"
const default_pic=require('@/assets/images/medical.jpg')
const default_avatar=require('@/assets/images/tx.jpeg')
const {host}=config

// 开发环境获取图片绝对路径
export const getImgUrl=(url)=>`${host}${url}`;

// 默认头像or 占位图
export const errPic=(event,type)=>{
    const img=event.srcElement;
    img.src=type==="avatar"?default_avatar:default_pic;
    img.onerror=null
  }
