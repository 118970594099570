import { createRouter, createWebHistory,createWebHashHistory,createMemoryHistory, } from "vue-router";
import Layout from "@/layout"
const routes = [
  {
    path: "/",
    name: "Layout",
    redirect:"/home",
    component: Layout,
    children:[
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/home/index.vue"),
        meta: {}
    }
    ]
  },
  {
    path: "/news",
    component: Layout,
    children:[
      {
        path: "",
        name: "News",
        component: () => import("@/views/news/index.vue"),
        meta: {
          title:"行业资讯"
        }
    }
    ]
  },
  {
    path: "/newsDetail",
    component: Layout,
    children:[
      {
        path: "",
        name: "NewsDetail",
        component: () => import("@/views/news/newsDetail.vue"),
        meta: {
          title:"资讯详情",
          activeMenu:"/news"
        }
    }
    ]
  },
  {
    path: "/authorDetail",
    component: Layout,
    children:[
      {
        path: "",
        name: "AuthorDetail",
        component: () => import("@/views/news/authorDetail.vue"),
        meta: {
          title:"作者详情",
          activeMenu:"/news"
        }
    }
    ]
  },
  {
    path: "/library",
    component: Layout,
    children:[
      {
        path: "",
        name: "Library",
        component: () => import("@/views/library/index.vue"),
        meta: {
          title:"政策文库"
        }
    }
    ]
  },
  {
    path: "/libraryDetail",
    component: Layout,
    children:[
      {
        path: "",
        name: "LibraryDetail",
        component: () => import("@/views/library/libraryDetail.vue"),
        meta: {
          title:"政策详情",
          activeMenu:"/library"
        }
    }
    ]
  },
  {
    path: "/company",
    component: Layout,
    children:[
      {
        path: "",
        name: "Company",
        component: () => import("@/views/company/index.vue"),
        meta: {
          title:"企业平台",
        }
    }
    ]
  },
  {
    path: "/companyDetail",
    component: Layout,
    children:[
      {
        path: "",
        name: "CompanyDetail",
        component: () => import("@/views/company/companyDetail.vue"),
        meta: {
          title:"厂商详情",
          activeMenu:'/company'
        }
    }
    ]
  },
  {
    path: "/certCompany",
    component: Layout,
    children:[
      {
        path: "",
        name: "CertCompany",
        component: () => import("@/views/company/certCompany.vue"),
        meta: {
          title:"厂商认证",
          activeMenu:'/company'
        }
    }
    ]
  },
  {
    path: "/bidding",
    component: Layout,
    children:[
      {
        path: "",
        name: "Bidding",
        component: () => import("@/views/bidding/index.vue"),
        meta: {
          title:"招投标",
        }
    }
    ]
  },
  {
    path: "/biddingDetail",
    component: Layout,
    children:[
      {
        path: "",
        name: "BiddingDetail",
        component: () => import("@/views/bidding/biddingDetail.vue"),
        meta: {
          title:"招投标详情",
          activeMenu:"/bidding"
        }
    }
    ]
  },
  
  {
    path: "/memberHome",
    component: Layout,
    children:[
      {
        path: "",
        name: "MemberHome",
        component: () => import("@/views/memberHome/index.vue"),
        meta: {
          title:"会员中心",
        }
    }
    ]
  },
  {
    path:"/protocal",
    component:Layout,
    children:[
      {
        path: "",
        name: "Protocal",
        component: () => import("@/views/protocal/protocal.vue"),
        meta: {
          title:"注册及购买协议",
        }
    }
    ]
  },
  
  {
    path:"/404",
    name:"NotFound",
    hidden:true,
    component:()=>import("@/views/404.vue")
  },
  


];
const router = createRouter({
  history:  createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.afterEach((to,from,next)=>{
  globalThis.scrollTo({
    top:0,
    behavior:"smooth"
  })
})
export default router;
