import article  from "./article";
import user from "./user";
import company from "./company"
import library from "./library"
import payment from "./payment";
import bidding from "./bidding";
export default{
    article,
    user,
    company,
    library,
    payment,
    bidding
}
// axios默认GET请求,如遇POST,请指定method
//  GET:request({url,params}) 
//  POST:request({url,data,method})