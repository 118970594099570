import {computed, reactive, ref} from "vue"
import {validPhone,validPassword,validPhoneCode} from "@/utils/validator"
import dialog from "@/utils/ui/dialog";
import $api from "@/api"
export default function useRegistForm(store,formType){
    const registForm=ref(null)
    const phoneCodeTips=ref("获取验证码")
    let timer=ref(null)
    const ruleState=reactive({
       rules:{
        phoneNo:[{validator:validPhone,trigger: "blur"}],
        password:[{validator:validPassword,trigger:"blur"}],
        phoneCode:[{validator:validPhoneCode,trigger:"blur"}]
       }
    })
    const form=reactive({
        phoneNo:"",
        phoneCode:"",
        password:"",
        repeatPwd:"",
    })
        
        const protocal = ref(false)
    // 获取手机验证码
    const getPhoneCode=async()=>{
    const phoneReg=/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
        if(!phoneReg.test(form.phoneNo) || !!timer.value)return;
        const [err,data]=await $api.user.getPhoneCode({mobile:form.phoneNo})
        if(!data.succeeded){
            dialog.message("error",data.errors)
            return;
        }
        countDown()

    }
    const disable=computed(()=>!Object.values(form).every(e=>!!e.length) || !protocal.value)
    // 倒计时60s
    const countDown=()=>{
        let defaultSecond=60;
        timer.value=setInterval(() => {
            if(defaultSecond===0){
                phoneCodeTips.value="获取验证码"
                clearInterval(timer.value)
                timer.value=null;
                return;
            }
            phoneCodeTips.value=`${--defaultSecond}S`
        }, 1000);
    }
    const register=()=>{
        registForm.value.validate(async(valid)=>{
            if(form.password!==form.repeatPwd){
                dialog.alert("提示","密码不一致,请确认",false,true,()=>{
                    form.repeatPwd=""
                })
                return;
            }
            if(valid){
                const reqestUrl=formType.value==="注册"?"register":"findPwd"
                const [err,data]=await $api.user[reqestUrl]({phone:form.phoneNo,code:form.phoneCode,password:form.password})
                if(!data.succeeded){
                    dialog.message("error",data.errors)
                }else{
                    for (const key in form) {
                        form[key]=""
                    }
                    store.commit("toggleRegistForm",false)
                    clearInterval(timer.value)
                    dialog.message("success",`${formType.value}成功,请妥善保存账号密码`)
                    
                }
            }else{
                dialog.alert("提示","输入有误,请重新输入") 
            }
        })
    }
    return {
        ruleState,
        form,
        registForm,
        getPhoneCode,
        phoneCodeTips,
        disable,
        register,
        protocal
    }
    
}