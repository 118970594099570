// 所有弹窗控制均放在此处
// 需要使用时commit，弹窗内部监听该store
const popupState={
    state:{
        orderInfo:{title:"",id:0,price:0,type:0},
        showLoginForm:false,
        showRegistForm:false,
        showOrdinaryForm:false, //普通会员查看付费信息,
        becomeMemberForm:false, //升级高级 or 超级会员
        becomeAnnualForm:false, //成为年度会员
        updateMemberForm:false
    },
    mutations:{
        setOrderInfo(state,payload){
            state.orderInfo=payload
        },
        toggleLoginForm(state,payload){
            state.showLoginForm=payload;
        },
        toggleRegistForm(state,payload){
            state.showRegistForm=payload
        },
        toggleOrdinaryForm(state,payload){
            state.showOrdinaryForm=payload
        },
        toggleBecomeMemberForm(state,payload){
            state.becomeMemberForm=payload
        },
        toggleUpdateMemberForm(state,payload){
            state.updateMemberForm=payload
        },

    }
}
export default popupState