import axios from "axios";
import WrapPromise from "./packPromise"
import $cookie from "@/utils/cookie"
import {
    ElLoading
} from "element-plus";
let loadingInstance = null
axios.defaults.withCredentials = true;
// wrapPromise 更好的捕获async await 异常, but 这个项目用不到 ，随缘吧
const service = axios.create({
    baseURL: "https://v1.hitzone.cn/api",
    timeout: 1000000
})
service.interceptors.request.use((config) => {
    const token=$cookie.getCookie('token')
    if(token){
        config.headers.Authorization=`Bearer ${token}`
    }
    // loadingInstance = ElLoading.service({
    //     text: "数据加载中~"
    // })
    if(config.method==="get"){
        // const timeStamp=Math.floor(Math.random()*9999+10000)
        // config["params"]=config?.params?Object.assign(config.params,{rand:timeStamp}):{rand:timeStamp}
    }
    return config
}, (err) => {

})
service.interceptors.response.use((rsp) => {
    // loadingInstance.close()
    if (rsp.status===200) {
        return WrapPromise(Promise.resolve(rsp.data))
    } else {
        return WrapPromise(Promise.reject(rsp.data))
    }
}, err => {
    // loadingInstance.close()
    return WrapPromise(Promise.reject(err))
})
export default service