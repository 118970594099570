import {
    createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import directives from "@/utils/directive"
import locale from 'element-plus/lib/locale/lang/zh-tw'
import "./utils/formatDate"
import "./utils/ppermission"
const app=createApp(App);
directives(app)
app.use(store)
app.use(router)
app.use(ElementPlus,[locale])
app.mount("#app");
// (()=>{
//     function block() { 
//         setInterval(() => {
//           (function (){}["constructor"]("debugger")["call"]())
//         }, 5);
//      }
//      try {
//         block()
//     } catch (error) {
        
//     }
// })()
