<template>
  <el-container>
    <el-header style="height:auto;padding:0" class="w100p" id="header">
        <div class="header auto">
          <div class="logo_box">
            <router-link class="logo db" to="/home"><img :src="require('@/assets/images/logo.png')"/></router-link>
          </div>
             <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :router="true">
               <el-menu-item v-for="(item,index) in tabs.filter(e=>!e.hidden)" class="el-menu-item" :key="item.id" :index="item.path">{{item.label}}</el-menu-item>
             </el-menu>
          <div class="input_box rel">
            <input class="inp" type="text" v-model="searchVal" placeholder="搜索" @keydown.enter="search()"/>
            <button class="abs" @click="search()"></button>
          </div>
          <div v-if="!!userInfo" class="member ">
            <el-dropdown>
              <span class="el-dropdown-link person-drop">
                {{userInfo.phone}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="item in personDrops" :key="item.label" @click="chooseFun(item)">
                    <svg class="icon" aria-hidden="true">
                        <use :xlink:href="`#icon-${item.icon}`"></use>
                    </svg>
                    <span style="padding-left:4px">
                      {{item.label}}
                    </span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="member" v-else>
            <a class="load" href="javascript:void(0);" @click="showPopup('login')">登录</a>
            <span>|</span>
            <a class="regi" href="javascript:void(0);" @click="showPopup('register')">注册</a>
          </div>
        </div>
          <login-form ></login-form>
          <regist-form ></regist-form>
          <article-payment-pop></article-payment-pop>
          <become-member></become-member>
          <update-member></update-member>
          <div class="mask" v-if="showMask"></div>
    </el-header>
    <el-main class="main">
        <router-view></router-view>
    </el-main>
    <el-footer class="w100p" style="height:auto">
        <div class="footer auto">
          <div class="footer_left">
            <img class="logo" :src="require('@/assets/images/yxb-white.png')" />
            <p>
              医信邦——传播医信行业价值平台。
              聚焦行业动态，关注医改政策，用严谨、专业的态度面对行业与读者，为各级卫健委领导、医院院长及管理者、信息科科长、医疗信息化及医药厂商提供最新动态。在这里，读懂中国医疗。用严谨、专业的态度面对行业与读者，为各级卫健委领导、医院院长及管理者、信息科科长、医疗信息化及医药厂商提供最新动态。在这里，读懂中国医疗。
            </p>
            <div class="copy_right">
              <span>医信邦版权所有@医信邦</span
              ><a href="http://www.beian.gov.cn/" target="_blank">蜀ICP备17012653号</a>
            </div>
          </div>
          <div class="footer_right">
            <img :src="require('@/assets/images/weixin.png')" />
            <p>扫一扫关注微信公众号</p>
          </div>
        </div>
    </el-footer>
  </el-container>
</template>
<script setup>
import {ref,computed, watch} from "vue"
import { ElContainer, ElHeader, ElFooter,ElDropdown,ElDropdownMenu,ElDropdownItem,ElMenu,ElMenuItem } from "element-plus";
import {useStore} from "vuex"
import {useRoute,useRouter} from "vue-router"
import LoginForm from "@/components/popup/loginForm"
import RegistForm from "@/components/popup/registForm"
import ArticlePaymentPop from "@/components/popup/articlePaymentPop"
import BecomeMember from '@/components/popup/becomeMember';
import UpdateMember from '@/components/popup/updateMember';

      const store = useStore()
      const route = useRoute()
      const router = useRouter()

    //#region 个人中心菜单
      const userInfo=computed(()=>store.state.userState.userInfo)
      const personDrops=ref([
        {label:"个人中心",icon:"person-home",path:"PersonHome"},
        {label:"退出登录",icon:"personal-quit"},
      ])
      const chooseFun=(item)=>{
        if(item.label==="退出登录"){
          store.commit("loginOut")
          router.removeRoute("PersonHome")
          if(router.currentRoute.value.fullPath==="/personHome"){
            router.push({
              name:"Home"
            })
          }
        }else{
          router.push({
          name:item?.path
        })
        }
        
      }
    //#endregion
    
    
    //#region 首页tab栏
      const tabs=ref([
        // {
        //     label:"招投标信息",
        //     id:0,
        //     isActive:true,
        //     path:"/bidding",

        //     hidden:false
        // },
        // {
        //     label:"政策文库",
        //     id:1,
        //     isActive:false,
        //     path:"/library",

        //     hidden:false
        // },
        {
            label:"企业平台",
            id:2,
            isActive:false,
            path:"/company",

            hidden:false
        },
        {
            label:"行业资讯",
            id:3,
            isActive:false,
            path:"/news",
            hidden:false
        }
        // {
        //     label:"会员中心",
        //     id:4,
        //     isActive:false,
        //     path:"/memberHome",
        //     hidden:false
        // }
    ])
      const activeIndex=computed(()=>{
        const { meta, path } = route;
        return meta?.activeMenu??path
      })
    //#endregion
    
  
    //#region 弹窗
      const showPopup=(type)=>{
        if(type==='login'){
          store.commit('toggleLoginForm',true)
        }else{
          store.commit('toggleRegistForm',true)
        }
      }
      const showMask=ref(false)
      watch(store.state.popup,(val)=>{
        console.log(store.state.popup);
        if(Object.values(val).filter(e=>typeof e==='boolean').some(e=>e)){
          showMask.value=true
        }else{
          showMask.value=false
        }
      })
    //#endregion
    
    //#region 搜索
    const searchVal=ref("")
    const search=async()=>{
      router.push({
        name:"News",
        query:{
          keywords:searchVal.value
        }
      })
    }
    //#endregion
</script>
<style>
.is-active{
  border: none !important;
  color: #1188f7 !important;
    position: relative;
}
</style>
<style lang="less" scoped>
:deep(.el-menu-demo>li){
  height: 72px;
    line-height: 84px;
    font-family: "puhui-m";
    font-size:18px;
    transition: all 0.3s 0s ease-in-out;
    -webkit-transition: all 0.3s 0s ease-in-out;
    padding:0 14px;
    border: none !important;
    color: black ;
}
.is-active:after{
       content: "";
    display: block;
    border-top: 2px solid #1188f7;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 50%;
    transform: translate(50%, 50%);
}
.login-regist-form{
  z-index: 999;
}
.input_box{
  margin-left: 65px;
}
.person-drop{
  cursor: pointer;
}
.mask{
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
  background: rgba(.1, .1, .1, .1);
}
.main{
  min-height: 600px;
}
.el-menu-item:hover{
    color:#409eff !important
}
</style>
