export default (app)=>{
    app.directive('disableSpace',{
        mounted:(el)=>{
            el.addEventListener("keydown",event=>{
                if(event.keyCode===32){
                    event.returnValue=false
                }
            })
        }
    })
    app.directive("onlyNum",{
        mounted:(el)=>{
            const allowKeys=["0","1","2","3","4","5","6","7","8","9","0"]
            el.addEventListener("keydown",event=>{
                if(allowKeys.every(e=>event.key!==e)){
                    event.returnValue=false
                }
            })
        }
    })
    app.directive("formatTime",(el,binding)=>{
        if(!!el.innerHTML){
            let time=el.innerHTML
            const u=navigator.userAgent.toLowerCase();
            const isApple=["mac"].some(e=>u.indexOf(e)>-1)
            if(isApple){
               time=time.replace(/-/g,"/")
            }
            el.innerHTML=new Date(time).format("yyyy-MM-dd")
        }
    })
}